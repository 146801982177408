import React from "react";

export default function Footer() {
  return (
    <footer className="bg-white w-full z-10 mt-[20%]">
      <div className="container mx-auto flex flex-wrap flex-col md:flex-row items-center justify-between p-5">
      </div>
    </footer>
  );
}
