import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App'; // Adjust the import path as necessary
import './index.css'; // Assuming you have some global styles

const container = document.getElementById('root'); // Ensure 'root' is the ID of your root DOM element
const root = createRoot(container); // Create a root.

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
