
import React from "react";
import { ChatBubbleOvalLeftIcon as Icon } from '@heroicons/react/24/solid';
import { useNavigate } from "react-router-dom";

export default function Contact(props) {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const history = useNavigate();

  const redirect = () => {
    window.location.href = '/'
  }

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }


  function handleSubmit(e) {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", name, email, message }),
    })
      .then(() => {
        console.log('Email sent');
        redirect();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        history.push('/');
      });
  }

  return (
    <section id="contact" className="relative">

      <div className="container px-5 pt-20 pb-0 mx-auto text-center max-w-[768px]">
        <Icon className="w-20 inline-block mb-4" />
        <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4">
          Get In Touch
        </h1>
        <p className="text-left leading-relaxed md:text-md lg:text-lg">
            If you have any ideas for collaborations or have questions about my latest projects, please don't hesitate to contact me. I am always open to discussing new opportunities and answering any questions you may have.
        </p>        
      </div>

      <div className="container px-5 pb-10 mx-auto flex flex-nowrap">
        <form
          netlify
          data-netlify-recaptcha="true"
          data-netlify="true"
          netlify-honeypot="bot-field"
          name="contact"
          onSubmit={handleSubmit}
          className="lg:w-1/3 md:w-1/2 flex flex-col md:mx-auto w-full mt-8">
          
          <input type="hidden" name="form-name" value="contact" />

          <div className="relative mb-4">
            <label htmlFor="name" className="leading-7 text-sm text-gray-400">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="w-full bg-white-200 rounded border border-gray-700 focus:border-gray-500 focus:ring-2 focus:ring-black-900 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-400">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full bg-white-200 rounded border border-gray-700 focus:border-black-500 focus:ring-2 focus:ring-black-900 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="message"
              className="leading-7 text-sm text-gray-400">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              className="w-full bg-white-200 rounded border border-gray-700 focus:border-gray-500 focus:ring-2 focus:ring-black-900 h-32 text-base outline-none py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div data-netlify-recaptcha="true" class="form-row"></div>
          <button
            type="submit"
            className="text-white bg-purple-900 border-0 py-2 px-6 focus:outline-none hover:bg-green-500 rounded text-lg">
            Submit
          </button>
        </form>
      </div>
    </section>
  );
}
