// Particles.js
import React, { useMemo, useState, useEffect } from "react";
import ParticlesJS, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const Particles = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initializeParticles = async () => {
      await initParticlesEngine(async (engine) => {
        await loadSlim(engine);
      });
      setIsInitialized(true);
    };

    initializeParticles();
  }, []);

  const particleOptions = useMemo(() => ({
    background: {
      color: {
        value: "#FFFFFF",
      },
    },
    fpsLimit: 120,
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        onHover: {
          enable: false,
          mode: "repulse",
        },
      },
      modes: {
        push: {
          quantity: 1,
        },
        repulse: {
          distance: 100,
          duration: 0.4,
        },
      },
    },
    fullScreen: { enable: false, zIndex: 0 },
    particles: {
      color: {
        value: "#111111",
      },
      links: {
        color: "#111111",
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      move: {
        direction: "none",
        enable: true,
        outModes: {
          default: "bounce",
        },
        random: true,
        speed: 1,
        straight: true,
      },
      number: {
        density: {
          enable: true,
        },
        value: 500,
      },
      opacity: {
        value: 0.6,
      },
      shape: {
        type: "circle",
      },
      size: {
        value: { min: 1, max: 5 },
      },
    },
    detectRetina: true,
  }), []);

  return isInitialized ? (
    <section id="particles" className="relative w-full h-[200px] overflow-hidden">
      <div id="tsparticles" className="absolute inset-0 -z-10 h-[200px]">
        <ParticlesJS options={particleOptions} />
      </div>
      <div className="absolute top-0 left-0 w-full h-full -z-10 bg-gradient-to-b from-transparent to-white pointer-events-none"></div>
      <div className="relative z-10">
        {/* Your other content here */}
      </div>
    </section>
  ) : null;
};

export default Particles;
