export const projects = [
    {
      title: "ProteomicsQC",
      subtitle: "",
      desc:
        "Interactive quality-control pipeline for quantitative proteomics.",
      image: "/img/projects-proteomicsqc.jpg",
      link: "https://lewisresearchgroup.github.io/ProteomicsQC/",
    },

    {
      title: "Metabolomics Integrator (ms-mint)",
      subtitle: "",
      desc:
        "Python library and web-application for scalable metabolomics.",
      image: "https://github.com/LewisResearchGroup/ms-mint-app/raw/develop/docs/image/MINT-logo.jpg",
      link: "https://lewisresearchgroup.github.io/ms-mint-app/",
    },

    {
      title: "CBSIC Dashboard",
      subtitle: "",
      desc:
        "Interactive dashboard to explore the Calgary Blood Stream Isolate Cohort (CBSIC).",
      image: "/img/Abg4102.F3.large.jpg",
      link: "https://www.resistancedb.org/software-tools",
    },

    {
      title: "CropXR",
      subtitle: "",
      desc:
        "Making crops more resilient, sustainable, and climate-adaptive through data-driven design.",
      image: "/img/project-cropxr.png",
      link: "https://cropxr.org/",
    },

    {
      title: "ResistanceDB",
      subtitle: "",
      desc:
        "Saving lives and combating antibiotic resistance by tapping into the immense data housed inside the microbe.",
      image: "/img/project-resistancedb.png",
      link: "https://www.resistancedb.org/prime-consortium",
    },
  ];


export const skills = [
  {"title": "Programming"},
  {"title": "Python"},
  {"title": "Conda"},
  {"title": "Mamba"},
  {"title": "Version control"},
  {"title": "Git"},
  {"title": "Github"},
  {"title": "DVC"},
  {"title": "Containerization"},
  {"title": "Docker"},
  {"title": "Data Analysis"},
  {"title": "Pandas"},
  {"title": "Dask"},
  {"title": "Data Visualization"},
  {"title": "Matplotlib"},
  {"title": "Seaborn"},
  {"title": "Plotly"},
  {"title": "Web-Development"},
  {"title": "Django"},
  {"title": "FastAPI"},
  {"title": "React"},
  {"title": "HTML"},
  {"title": "CSS"},
  {"title": "Interactive Dashboards"},
  {"title": "Plotly-Dash"},
  {"title": "Deep Learning"},
  {"title": "PyTorch"},
  {"title": "Tensorflow"},
  {"title": "Fastai"},
  {"title": "Machine Learning"},
  {"title": "Scikit-Learn"},
  {"title": "PyCaret"},
  {"title": "Chemo-Informatics"},
  {"title": "RDKit"},
  {"title": "Network Analysis"},
  {"title": "NetworkX"},
  {"title": "Holoviews"},
  {"title": "PyVis"}
]


export const testimonials = [
    {
      title: "React Reserve",
      subtitle: "MERN Stack",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
      image: "./project-1.gif",
      link: "https://reactbootcamp.com",
    },
  ];

  
export const me = {
  name: 'Dr. Sören Wacker',
  role: "Research Engineer & Data Science Consultant",
  bio: "I advance scientific research by developing and implementing computational solutions across a diverse spectrum of emerging technologies, bridging the gap between traditional research disciplines and state-of-the-art computational methodologies."}
