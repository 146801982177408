import { VideoCameraIcon } from '@heroicons/react/24/solid';
import YoutubeEmbed from "./YoutubeEmbed";


export default function Media() {
  return (
    <section id="media">
      <div className="container px-auto py-0 mx-auto">       
        <div className="text-center mb-10">
          <VideoCameraIcon className="w-10 inline-block mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-black mb-2">
            Media
          </h1>
        </div>
        <div className='justify-center mx-10 mb-5'>     
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            With the use of supercomputers and advanced algorithms, 
            molecular dynamics simulations can simulate complex systems with great 
            accuracy and efficiency, giving scientists the ability to make predictions 
            and test theories that would be impossible to study experimentally. 
          </p>
        </div>
        <div className='justify-center mx-10 max-w-[800px] mx-auto'>     
          <YoutubeEmbed embedId='xziahnT_gd4' caption='Molecular Dynamics Simulation' />
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto justify-center mt-5">
          </p>
        </div>
        <div className='justify-center mx-10 mt-4 mb-20'>     

          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto"> 
            These simulations have been instrumental in advancing various fields, 
            such as drug design, materials science, and biochemistry, and hold great 
            potential for continued discovery and innovation.
          </p>
        </div>
      </div>
    </section>
  );
}
