
import React, { useEffect, useState } from "react";
import { CogIcon as Icon } from "@heroicons/react/24/solid";
import { projects } from "../data";


export default function Projects() {
  const [shuffledProjects, setShuffledProjects] = useState([]);

  useEffect(() => {
    // Function to shuffle the projects array randomly
    const shuffleArray = (array) => {
      const shuffled = [...array];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    };

    // Shuffle the projects array when the component mounts
    setShuffledProjects(shuffleArray(projects));
  }, []);

  return (
    <section id="projects">
      <div className="container px-5 py-20 mx-auto text-center my-[5%]  max-w-[768px]">
        <div className="text-center mb-20">
          <Icon className="w-20 inline-block mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4">
            Projects
          </h1>
          <p className="text-left leading-relaxed md:text-md lg:text-lg">
            I am proud of the impactful projects I have undertaken and the innovative solutions that I have delivered. I am dedicated to tackling complex challenges and pushing the boundaries of what's possible. Take a closer look at some of my past and present projects:</p>
        </div>
        <div className="flex flex-wrap -m-4 justify-center items-center">
          {shuffledProjects.map((project, index) => (
            <a href={project.link} target="_blank" rel="noreferrer">
              <div
                key={index}
                className="p-4 md:flex md:items-center hover:bg-gray-200" // Use md:flex to apply flex on non-small screens
                style={{ width: '100%', maxWidth: '768px', height: 'auto' }} // Ensure the container adapts to content
              >
                <div className="md:w-1/3">
                  <img
                    alt="project"
                    className="md:w-full h-auto object-cover w-[50%] mx-auto" // Ensure the image covers the designated area appropriately
                    src={project.image}
                  />
                </div>
                <div className="md:w-2/3 p-4 flex flex-col justify-center text-left">
                  <h1 className="title-font text-lg md:text-xl font-medium mb-3">
                    {project.title}
                  </h1>

                  <p className="mb-1 md:text-md lg:text-lg"> {/* Apply consistent styling here */}
                    {project.desc}
                  </p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
}

