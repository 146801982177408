import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import Link and useLocation
import { HashLink as Link } from 'react-router-hash-link';
import { handleScroll } from "../utils"; // Import the slugify function from your utility file

export default function Navbar() {
  const location = useLocation(); // useLocation hook to get current location

  // useEffect to handle scrolling after navigating from other pages
  useEffect(() => {
    // Check if there's a scrollTo state in location state object
    if (location.state && location.state.scrollTo) {
      handleScroll(location.state.scrollTo);
    }
  }, [location]); // Re-run the effect when location changes

  return (
    <header className="bg-purple-900 md:sticky top-0 z-10 text-white">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <div className="title-font font-medium mb-4 md:mb-0">
          {/* Use Link here */}
          <Link smooth to="#/about" onClick={() => handleScroll('about')} className="ml-3 text-xl hover:text-blue-200">
            sorenwacker.net
          </Link>
        </div>
    
        <nav className=" md:ml-auto md:py-1 md:pl-4	flex flex-wrap items-center text-base justify-center">
          {/* Update these to use Link as well */}
          <Link smooth to="#/projects" onClick={() => handleScroll('projects')} className="mr-5 hover:text-blue-200">
            Projects
          </Link>

          <Link smooth to="#/media" onClick={() => handleScroll('media')} className="mr-5 hover:text-blue-200">
            Media
          </Link>

          <a href="https://scholar.google.ca/citations?hl=en&user=bIBGlKsAAAAJ&view_op=list_works&authuser=2&sortby=pubdate" className="mr-5 hover:text-blue-200" target="_blank" rel="noreferrer">
            Publications
          </a>

          <Link
            smooth to="#/contact"
            onClick={() => handleScroll('contact')}
            className="mr-5 hover:text-blue-200">
            Contact
          </Link>

        </nav>
    


      </div>
    </header>
  );
}
