import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => (
  <div className="aspect-[4/3]">
    <iframe
      className='h-[100%] h-[100%] rounded-lg'
      width="100%" 
      src={`https://www.youtube.com/embed/${embedId}?vq=hd1080p;feature=oembed&amp;controls=1&amp;hd=1&amp;modestbranding=1&amp;autohide=1&amp;showinfo=0`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);


YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;


